import React, { Component } from 'react'
import PageHelmet from '../components/PageHelmet'

class PageSearch extends Component {
  componentDidMount() {
    global.window.__gcse = {
      parsetags: 'explicit',
      callback: () => {
        global.google.search.cse.element.render({
          div: 'gcse',
          tag: 'search',
        });
      },
    };
    (function() {
      var cx = '008769535162340018257:ffl9_v-jris';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;

      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();
  }

  render() {
    return (
      <>
        <PageHelmet title="Search" />
        <h1 className="pb-2 mb-2 border-bottom missingSecondMenu">Search</h1>
        <div id="gcse" />
      </>
    )
  }
}

export default PageSearch
